import { Container, Row, Col} from "react-bootstrap"
import Logo from "../assets/img/logogb.png";
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';

export const Footer = () =>{
    return(
        <footer className="footer">
            <Container>
                <Row className="align-item-center">
                 <Col sm={6}>
                    <img src={Logo}/>
                 </Col>            
                 <Col sm={6} className="text-center text-sm-end">
                    <div className="social-icon">
                        <a href="https://www.facebook.com/gbcreativos1" target="_blank"><img src={navIcon2}/></a>
                        <a href="https://www.instagram.com/gbcreativos/" target="_blank"><img src={navIcon3}/></a>
                    </div>
                    <p>CopyRight 2023. Todos los derechos reservados.</p>
                 </Col>
                </Row>  
            </Container>
        </footer>
    )
}