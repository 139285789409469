import { Container, Col, Row, Nav, Tab } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import colorSharp2 from "../assets/img/color-sharp2.png";
import projImg1 from "../assets/img/web_estanciapv.jpg";
import projImg2 from "../assets/img/web_suites.jpg";
import projImg3 from "../assets/img/web_bile.jpg";


import projImg4 from "../assets/img/face_estanciaguay.jpg";
import projImg5 from "../assets/img/face_colchorama.jpg";
import projImg6 from "../assets/img/face_estanciavta.jpg";
import projImg7 from "../assets/img/face_barrabas.jpg";
import projImg8 from "../assets/img/face_lasbrasas.jpg";
import projImg9 from "../assets/img/face_manantial.jpg";
import projImg10 from "../assets/img/face_suites.jpg";
import projImg11 from "../assets/img/face_vizcaino.jpg";
import projImg12 from "../assets/img/face_casamar1928.jpg";
import projImg13 from "../assets/img/face_casatradicionalpm.jpg";
import projImg14 from "../assets/img/face_suites.jpg";
import projImg15 from "../assets/img/face_compos.jpg";


import projImg16 from "../assets/img/insta_sancarlos.jpg";
import projImg17 from "../assets/img/insta_casamar1928.jpg";
import projImg18 from "../assets/img/insta_casatradicional1928.jpg";


import projImg19 from "../assets/img/logo4.jpg";
import projImg20 from "../assets/img/logo6.jpg";
import projImg21 from "../assets/img/logo14.jpg";
import projImg22 from "../assets/img/logo7.jpg";
import projImg23 from "../assets/img/logo2.jpg";
import projImg24 from "../assets/img/logo8.jpg";
import projImg25 from "../assets/img/logo5.jpg";
import projImg26 from "../assets/img/logo10.jpg";
import projImg27 from "../assets/img/logo9.jpg";

import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
    const projects = [
        {
            title: "Estancia San Carlos Guayabitos",
            description: "Website",
            imgUrl: projImg1,
            Urll: "https://bungalowsestanciasancarlos.com.mx/",
        },        
        {
            title: "Grupo BILE",
            description: "Website",
            imgUrl: projImg3,
            Urll: "https://grupobile.com/",
        },        
        {
            title: "Suites del Carmen",
            description: "Website",
            imgUrl: projImg2,
            Urll: "http://suitesdelcarmen.com.mx/",
        },       
        {
            title: "Estancia San Carlos Vallarta",
            description: "Instagram",
            imgUrl: projImg16,
            Urll: "https://www.instagram.com/estanciasancarlos_guayabitos/",
        },        
        {
            title: "Casamar 1928",
            description: "Instagram",
            imgUrl: projImg17,
            Urll: "https://www.instagram.com/casamar1928/",
        },        
        {
            title: "Casa Tradicional PV",
            description: "Instagram",
            imgUrl: projImg18,
            Urll: "https://www.instagram.com/casatradicionalpv/",
        }
    ];
    const fabinsta = [    
        {
            title: "Hotel Estancia San Carlos",
            description: "FB Fan Page",
            imgUrl: projImg4,
            Urll: "https://www.facebook.com/HotelEstanciaSanCarlos",
        },        
        {
            title: "Colchorama de Vallarta",
            description: "FB Fan Page",
            imgUrl: projImg5,
            Urll: "https://www.facebook.com/colchoramadevallartasadecv",
        },        
        {
            title: "Estancia San Carlos Vallarta",
            description: "FB Fan Page",
            imgUrl: projImg6,
            Urll: "https://www.facebook.com/EstanciaSanCarlosVallarta",
        },
        {
            title: "Mariscos Barrabas",
            description: "FB Fan Page",
            imgUrl: projImg7,
            Urll: "https://www.facebook.com/mariscosbarrabas",
        },        
        {
            title: "Las Brasas Compostela",
            description: "FB Fan Page",
            imgUrl: projImg8,
            Urll: "https://www.facebook.com/lasbrasascompostela",
        },        
        {
            title: "Refresqueria El Manantial",
            description: "FB Fan Page",
            imgUrl: projImg9,
            Urll: "https://www.facebook.com/refresqueriaelmanantial1",
        },        
        {
            title: "Suites del Carmen",
            description: "FB Fan Page",
            imgUrl: projImg10,
            Urll: "https://www.facebook.com/suitesdelcarmen",
        },        
        {
            title: "Francisco Gabriel Vizcaino",
            description: "FB Fan Page",
            imgUrl: projImg11,
            Urll: "https://www.facebook.com/FranciscoGVizcainoCiclos",
        },        
        {
            title: "Casamar 1928",
            description: "FB Fan Page",
            imgUrl: projImg12,
            Urll: "https://www.facebook.com/Casamar1928",
        },     
        {
            title: "Casa Tradicional Cocina Mexicana Punta de Mita",
            description: "FB Fan Page",
            imgUrl: projImg13,
            Urll: "https://www.facebook.com/casatradicionalpuntamita",
        },        
        {
            title: "Suites del Carmen",
            description: "FB Fan Page",
            imgUrl: projImg14,
            Urll: "https://www.facebook.com/suitesdelcarmen",
        },
        {
            title: "Compostela Magico",
            description: "FB Fan Page",
            imgUrl: projImg15,
            Urll: "https://www.facebook.com/compostelamagico1",
        }   
    ];
    const branding = [            
        {
            title: "Rancho Chiota",
            description: "Branding",
            imgUrl: projImg19,
            Urll: "",
        },        
        {
            title: "CDTI",
            description: "Branding",
            imgUrl: projImg20,
            Urll: "",
        },    
        {
            title: "Compostela Magico",
            description: "Branding",
            imgUrl: projImg21,
            Urll: "",
        },     
        {
            title: "Tostinay",
            description: "Branding",
            imgUrl: projImg22,
            Urll: "",
        },    
        {
            title: "Mi Placita",
            description: "Branding",
            imgUrl: projImg23,
            Urll: "",
        },  
        {
            title: "Nayarit",
            description: "Branding",
            imgUrl: projImg24,
            Urll: "",
        },     
        {
            title: "Jessmar Agencia de Viajes",
            description: "Branding",
            imgUrl: projImg25,
            Urll: "",
        },    
        {
            title: "Café de Reyes",
            description: "Branding",
            imgUrl: projImg26,
            Urll: "",
        } ,
        {
            title: "Playa Azul",
            description: "Branding",
            imgUrl: projImg27,
            Urll: "",
        }
    ];
    return(
        <section className="project" id="projects">
            <Container>
                <Row>
                    <Col>
                        
                        <TrackVisibility>
                        {({isVisible}) =>
                        <div className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                        <h2>Proyectos</h2>
                        <p>Hemos trabajado en proyectos desafiantes que van desde la creación de sitios web corporativos hasta el desarrollo de plataformas de comercio electrónico. Nuestro objetivo principal es ofrecer productos digitales intuitivos y atractivos, optimizados para la mejor experiencia del usuario.</p>
                        </div>}
                        </TrackVisibility>
                        <Tab.Container id="projects-tabs" defaultActiveKey="first">
                        <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Fan Page</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Web/Instagram</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third">Branding </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <Row>
                                    {
                                        fabinsta.map((project, index) => {
                                            return(
                                                <ProjectCard
                                                key={index}
                                                {...project}
                                                />
                                            )
                                        })
                                    }
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                             <Row>
                             {
                                        projects.map((project, index) => {
                                            return(
                                                <ProjectCard
                                                key={index}
                                                {...project}
                                                />
                                            )
                                        })
                                    }
                             </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                            <Row>
                             {
                                        branding.map((project, index) => {
                                            return(
                                                <ProjectCard
                                                key={index}
                                                {...project}
                                                />
                                            )
                                        })
                                    }
                             </Row>
                            </Tab.Pane>
                        </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>              
            </Container>
            <img className="background-image-right" src={colorSharp2}></img>
        </section>
    );
}