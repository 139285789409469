import { Col } from "react-bootstrap"

export const ProjectCard = ({title, description, imgUrl, Urll}) => {
    return(
        <Col sm={6} md={4}>
            <div className="proj-imgbx">
            <a href={Urll} target="_blank">
                <img src={imgUrl}/>
                <div className="proj-txtx">
                    <h4>{title}</h4>
                    <span>{description}</span>
                </div>
                
        </a>
            </div>
        </Col>
    )
}